import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import { FormikFormGroup } from 'components/common';
import { Button, Input } from 'components/bootstrap';

import { Backend, S3BackendSettings } from 'archive/types';

const AWSKeySecretFieldGroup = () => {
  const { values: { settings }, setFieldValue } = useFormikContext<Backend>();
  const s3Settings: S3BackendSettings = settings;
  const isKeySet = (s3Settings.aws_secret_access_key && 'is_set' in s3Settings.aws_secret_access_key) ? s3Settings.aws_secret_access_key.is_set : false;
  const isCreate = useRef(!isKeySet);
  const [keyValue, setKeyValue] = useState('');
  const [showResetPasswordButton, setShowResetPasswordButton] = useState(isKeySet);

  const setAccessKey = useCallback((nextAccessKey) => {
    setFieldValue('settings.aws_secret_access_key', nextAccessKey);
  }, [setFieldValue]);

  useEffect(() => {
    if (isKeySet) {
      setAccessKey({ keep_value: true });
    }
  }, [isKeySet, setAccessKey]);

  const toggleAccessKeyReset = useCallback(() => {
    if (showResetPasswordButton) {
      setAccessKey({ delete_value: true });
      setShowResetPasswordButton(false);

      return;
    }

    setAccessKey({ keep_value: true });
    setShowResetPasswordButton(true);
  }, [setAccessKey, showResetPasswordButton]);

  const handleAccessKeyChange = useCallback(({ target }) => {
    const typedKey = target.value;
    const nextKey = typedKey === ''
      ? { delete_value: true } : { set_value: typedKey };

    setKeyValue(typedKey);
    setAccessKey(nextKey);
  }, [setAccessKey]);

  return (
    <>
      <FormikFormGroup name="settings.aws_access_key_id"
                       type="text"
                       label="AWS Access Key"
                       placeholder="AK****************"
                       help='Your AWS Key should be a 20-character long, alphanumeric string that starts with the letters "AK".'
                       required />
      {showResetPasswordButton
        ? (
          <Input id="aws_secrete_key_reset"
                 label="AWS Secrete Key"
                 labelClassName="col-sm-3"
                 wrapperClassName="col-sm-9">
            <Button onClick={toggleAccessKeyReset}>Reset password</Button>
          </Input>
        )
        : (
          <Input name="settings.aws_secret_access_key"
                 id="aws_secrete_key"
                 type="password"
                 onChange={handleAccessKeyChange}
                 label="AWS Secret Key"
                 buttonAfter={!isCreate.current ? (
                   <Button type="button" onClick={toggleAccessKeyReset}>
                     Undo Reset
                   </Button>
                 ) : undefined}
                 placeholder="****************"
                 value={keyValue}
                 help="Your AWS Secret is usually a 40-character long, base-64 encoded string."
                 labelClassName="col-sm-3"
                 wrapperClassName="col-sm-9"
                 required />
        )}
    </>
  );
};

export default AWSKeySecretFieldGroup;
