import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { mapValues } from 'lodash';

import type { Backend } from 'archive/types';
import ArchiveBackendNameDescriptionFormGroup from 'archive/components/ArchiveBackendNameDescriptionFormGroup';
import ArchiveBackendOutputPathFieldGroup from 'archive/components/ArchiveBackendOutputPathFieldGroup';
import { BackendPropType } from 'archive/propTypes';
import { FILESYSTEM_TYPE, S3_TYPE } from 'archive/ArchiveStore';
import { LinkContainer } from 'components/common/router';
import { Select } from 'components/common';
import { Button, ButtonToolbar, HelpBlock, Input } from 'components/bootstrap';

import ArchiveBackendS3FieldGroup from './ArchiveBackendS3FieldGroup';
import AWSRegionsFormGroup from './aws/AWSRegionsFormGroup';

type Props = {
    backend?: Backend,
    checkOutputPath: (path: string) => Promise<any>,
    updateConfiguration: (backend: Backend) => Promise<any>,
    cancelURL: string,
}

const defaultFormValues: Backend = { title: '', description: '', settings: { type: undefined } };
const BACKEND_TYPE_OPTIONS = [{ value: FILESYSTEM_TYPE, label: 'File system' }, { value: S3_TYPE, label: 'S3' }];

const _formatBackendValidationErrors = (backendErrors: { [fieldName: string]: string[] }) => {
  const backendErrorStrings = mapValues(backendErrors, (errorArray) => `${errorArray.join(' ')}`);
  const { title, description, ...rest } = backendErrorStrings;

  return {
    title: title || undefined,
    description: description || undefined,
    settings: {
      ...rest,
    },
  };
};

const ArchiveBackendConfigForm = ({ backend, checkOutputPath, updateConfiguration, cancelURL }: Props) => {
  let initialValues: Backend;

  if (backend?.id) {
    const { id, title, description, settings } = backend;
    initialValues = { id, title, description, settings };
  } else {
    initialValues = defaultFormValues;
  }

  const _handleSubmit = (values, { setErrors }) => {
    updateConfiguration(values).catch((error) => {
      if (typeof error?.additional?.body?.errors === 'object') {
        setErrors(_formatBackendValidationErrors(error.additional.body.errors));
      }
    });
  };

  return (
    <Formik initialValues={initialValues}
            onSubmit={_handleSubmit}>
      {({
        values,
        setFieldValue,
      }) => {
        return (
          <Form>
            <Field name="settings.type">
              {() => (
                <Input help="Select the Backend type you want to configure."
                       id="default-backend-type-select"
                       label="Backend Type">
                  <Select id="backendType"
                          name="BackendType"
                          placeholder="Select Backend Type"
                          options={BACKEND_TYPE_OPTIONS}
                          matchProp="label"
                          disabled={!!values?.id}
                          onChange={(option) => {
                            setFieldValue('settings.type', option);
                          }}
                          value={values.settings.type} />
                  <HelpBlock />
                </Input>
              )}
            </Field>
            {values.settings.type && <ArchiveBackendNameDescriptionFormGroup />}
            {values.settings.type === S3_TYPE && (
            <>
              <ArchiveBackendS3FieldGroup />
              <AWSRegionsFormGroup />
            </>
            )}
            {values.settings.type && (
            <ArchiveBackendOutputPathFieldGroup checkOutputPath={checkOutputPath} />
            )}
            {values.settings.type && (
            <ButtonToolbar>
              <Button type="submit" bsStyle="success">Save</Button>
              <LinkContainer to={cancelURL}>
                <Button bsStyle="default">Cancel</Button>
              </LinkContainer>
            </ButtonToolbar>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

ArchiveBackendConfigForm.propTypes = {
  backend: BackendPropType,
  checkOutputPath: PropTypes.func.isRequired,
  updateConfiguration: PropTypes.func.isRequired,
  cancelURL: PropTypes.string.isRequired,
};

ArchiveBackendConfigForm.defaultProps = {
  backend: {
    id: undefined,
    title: '',
    description: '',
    settings: {
      type: undefined,
    },
  },
};

export default ArchiveBackendConfigForm;
